body {
    background-color: white;
}

/* Custom classes for buttons */
.react-confirm-alert-button-group > .green-button {
    background-color: #2dccd3; /* Green complementary to #2dccd3 */
    color: white;
  }
  
  .react-confirm-alert-button-group >   .red-button {
    background-color: rgb(255, 0, 0); /* Red complementary to #2dccd3 */
    color: white;
  }
  